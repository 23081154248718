import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    TMenuProduct, TExtraOption, TRemovableTopping, TValidateBasketRequestItem, TProductModifiers
} from '@hcstechnologies/fuocos-interfaces/src/types';
import { reject } from 'lodash';
import { Close, Remove, Add, AddCircle, RemoveCircle } from '@mui/icons-material';
import {
    CardMedia, Stack, Grid, List, ButtonBase, Checkbox, FormControlLabel,
    Button, DialogContent, DialogTitle, Box, useTheme, Typography, Fab,
    ListItemText, useMediaQuery, LinearProgress, Radio, RadioGroup, FormControl, Divider,
    Chip
} from '@mui/material';
import { useBasket } from '../hooks/use-basket';
import { FuocosListItemButton, MonoTypography, BlurredDialog, WarningDialog } from '@hcstechnologies/fuocos-themes';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { PostcodeContext } from '../contexts/LocationContext';
import { assertNotNull } from '../shared/utils';

type TModifierComponent = {
    modifier: TProductModifiers;
    modifierIndex: number;
    toggleExtra: (extra: TExtraOption) => void;
    toggleItem: (item: TExtraOption) => void;
    hasExtra: (productId: string) => boolean;
    hasItem: (productId: string) => boolean;
    handleValidationChange: (validSelection: boolean, modifierIndex: number) => void
}

type TModiferCheckBoxOptions = {
    modifier: TProductModifiers,
    options: TExtraOption;
    toggleExtra: (extra: TExtraOption) => void;
    toggleItem: (item: TExtraOption) => void;
    handleValidation: () => void;
}

type TModifierRadioGroupOptions = {
    modifier: TProductModifiers;
    toggleExtra: (extra: TExtraOption) => void;
    toggleItem: (item: TExtraOption) => void;
    handleValidation: () => void;
}

type TModifierListOptions = {
    modifier: TProductModifiers
    options: TExtraOption[];
    maxAllowed: number;
    toggleExtra: (extra: TExtraOption) => void;
    toggleItem: (item: TExtraOption) => void;
    hasExtra: (productId: string) => boolean;
    hasItem: (productId: string) => boolean;
    handleValidation: (selectedCount: number) => void;
}

type TAddToBasketDialogArgs = {
    product: TMenuProduct | undefined;
    locationPath: string;
    open: boolean;
    handleClose: (event?: unknown, reason?: 'escapeKeyDown' | 'backdropClick' | undefined) => void;
    hideProductImage?: boolean;
    menuId?: string;
};


/**
 * Display a dialog with product details and allow user to add product to basket with config
 * @param param0
 * @returns
 */
const AddToBasketDialog: React.FC<TAddToBasketDialogArgs> = ({ product, locationPath, open, handleClose, hideProductImage = false, menuId }) => {
    const analytics = getAnalytics();
    const basket = useBasket();
    const theme = useTheme();
    const [quantity, setQuantity] = useState(1);
    const [extras, setExtras] = useState<TValidateBasketRequestItem[]>([]);
    const [extraItems, setItems] = useState<TValidateBasketRequestItem[]>([]);
    const [removed, setRemoved] = useState<TRemovableTopping[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [disableAddToBasket, setDisableAddToBasket] = useState(true);
    const [verification, openVerification] = useState<boolean>(false);
    const [proofOfAgeAccepted, setProofOfAge] = useState(false);
    const [viewExtras, setViewExtras] = useState(false);
    const [modifiersValid, setModifiersValid] = useState<string[]>([]);
    const isPortrait = useMediaQuery(theme.breakpoints.down('lg'));
    const { postcode: userLocation } = useContext(PostcodeContext);


    /** Check to decide whether 'add to basket' should be enabled/disabled */
    useEffect(() => {

        // If there is an invalid modifier then the button should be disabled. If there are no modifiers
        // on the product then the button should be enabled.
        (!modifiersValid.includes('invalid') && modifiersValid.length > 0) || !product?.modifiers
            ? setDisableAddToBasket(false) : setDisableAddToBasket(true);

    }, [modifiersValid]);


    useEffect(() => {
        setQuantity(1);
        setExtras([]);
        setRemoved([]);
        setViewExtras(false);
        setBusy(false);
        openVerification(true);
        setProofOfAge(false);
        // If a modifier has no minimum required then it defaults to valid, otherwise it is invalid
        setModifiersValid(product?.modifiers?.map((mod) => {
            return mod.minRequired === 0 ? 'valid' : 'invalid';
        }) ?? []);
    }, [open === true]);

    const handleVerificationClose = (
        event?: object, reason?: 'escapeKeyDown' | 'backdropClick'): void => {
        if (reason == 'backdropClick') return;
        openVerification(false);
    };

    const confirmVerification = () => {
        setProofOfAge(true);
        handleVerificationClose();
    };

    const rejectVerification = () => {
        handleClose();
        handleVerificationClose();
    };

    /** Do not render if product is undefined */
    if (product === undefined) return <></>;

    /** Add supplied product to basket */
    const addToBasket = async (product: TMenuProduct | undefined, menuId?: string) => {
        if (location === null) throw new Error('No Location Selected');

        if (product === undefined) throw new Error('No Product Selected');

        setBusy(true);

        try {
            await basket.addProductToBasket(product, quantity, extras, extraItems, removed, locationPath, userLocation, menuId);
        } catch (error) {
            console.error(error);
        }

        setBusy(false);

        handleClose();

        logEvent(analytics, 'add_to_cart', {
            currency: 'GBP',
            items: [
                {
                    item_id: product.productId,
                    item_name: product.name,
                    index: 0,
                    item_category: product.category?.name,
                    location_id: locationPath,
                    price: product.salePrice,
                    quantity: product.saleQty
                }
            ]
        });
    };

    /**
     * Add or remove items from the extras array
     * @param extra
     */
    const toggleExtra = (extra: TExtraOption): void => {
        if (extras.find((e) => e.productId === extra.productId)) {
            setExtras(reject(extras, (e) => e.productId === extra.productId));
        } else {
            setExtras([
                ...extras,
                { productId: extra.productId, quantity: 1, productName: extra.name },
            ]);
        }
    };

    /**
     * Add or remove items from the extraItems array
     * @param item
     */
    const toggleItem = (item: TExtraOption): void => {
        if (extraItems.find((e) => e.productId === item.productId)) {
            setItems(reject(extraItems, (e) => e.productId === item.productId));
        } else {
            setItems([
                ...extraItems,
                { productId: item.productId, quantity: 1, productName: item.name },
            ]);
        }
    };

    /**
     * Add or remove items from the extras array
     * @param extra
     */
    const toggleRemoved = (extra: TRemovableTopping): void => {
        if (removed.find(e => e.productId === extra.productId)) {
            setRemoved(reject(removed, e => e.productId === extra.productId));
        } else {
            setRemoved([...removed, { productId: extra.productId, name: extra.name }]);
        }
    };

    /**
     * Check so see if extras list includes productId
     * @param productId
     * @returns
     */
    const hasRemoved = (productId: string): boolean => {
        return removed?.map(e => e.productId).includes(productId) ?? false;
    };

    /** When a modifier becomes valid/invalid we want to update its status in the modifiersValid
     * array that determines whether 'add to basket' should be enabled/disabled
     */
    const handleValidationChange = (validSelection: boolean, modifierIndex: number) => {

        const temp = [...modifiersValid];
        temp[modifierIndex] = validSelection ? 'valid' : 'invalid';
        setModifiersValid(temp);
    };

    const renderAllergens = () => {
        return <Stack direction="row" justifyContent="center" m={theme.spacing(1)} width='100%'
            sx={{
                [theme.breakpoints.down('lg')]: { width: 'max-content' }
            }}>
            {product?.tags?.map((tag, index) => tag.imageUrl &&
                <CardMedia
                    key={index}
                    aria-label={tag.name}
                    component="img"
                    src={tag.imageUrl}
                    id={tag.name}
                    height={theme.spacing(8)}
                    width='auto'
                />
            )}
        </Stack>;
    };

    const renderExtras = () => {
        return (
            <React.Fragment>
                <Grid container item direction={isPortrait ? 'row' : 'column'}
                    justifyContent={isPortrait ? 'center' : 'flex-start'} height='100%' width='50%'
                    sx={{
                        overflowY: 'auto',
                        [theme.breakpoints.down('lg')]:
                            { width: '100%', overflowY: 'scroll', height: '200px', flexWrap: 'wrap' }, flexWrap: 'nowrap'
                    }}>
                    <Typography width='100%' variant="h6" align="center" mb={2} pl={theme.spacing(2)}>
                        Tell us how you want it!
                    </Typography>
                    <Stack direction='column' flexGrow={1}>

                        <Typography variant='h5' gutterBottom align='center' pl={theme.spacing(2)}>
                            Extra Toppings
                        </Typography>

                        <List sx={{ width: '100%' }}>

                            {product?.extraOptions?.map((extra) => (

                                <FuocosListItemButton
                                    key={extra.productId}
                                    onClick={() => toggleExtra(extra)}
                                    selected={hasExtra(extra.productId)}>

                                    <ListItemText>

                                        <Stack direction='row' alignItems='center'>

                                            <MonoTypography variant="h6" align="left">
                                                {extra.name}
                                            </MonoTypography>

                                            <Box sx={{ flex: 1 }} />

                                            <MonoTypography variant="h6" align="right"
                                                marginRight={theme.spacing(2)}>
                                                £{extra.salePrice.toFixed(2)}
                                            </MonoTypography>

                                            {hasExtra(extra.productId) ? <RemoveCircle /> : <AddCircle />}

                                        </Stack>

                                    </ListItemText>

                                </FuocosListItemButton>
                            ))}

                        </List>

                        {/* Removable Toppings */}
                        {(product.removableToppings ?? []).length > 0 &&
                            <React.Fragment>
                                <Typography variant='h5' gutterBottom align='center'
                                    sx={{
                                        [theme.breakpoints.down('lg')]: { mt: 2, pl: theme.spacing(2) }
                                    }}>
                                    Standard Toppings
                                </Typography>
                                <List sx={{ width: '100%' }}>
                                    {product.removableToppings?.map(topping => (

                                        <FuocosListItemButton key={topping.productId}
                                            onClick={() => toggleRemoved(topping)} selected={!hasRemoved(topping.productId)}>

                                            <ListItemText>

                                                <Stack direction='row' alignItems='center'>

                                                    <MonoTypography variant='h6' align="left">
                                                        {topping.name}
                                                    </MonoTypography>

                                                    <Box sx={{ flex: 1 }} />

                                                    {hasRemoved(topping.productId) ? <AddCircle /> : <RemoveCircle />}

                                                </Stack>

                                            </ListItemText>

                                        </FuocosListItemButton>

                                    ))}

                                </List>
                            </React.Fragment>
                        }
                    </Stack>

                </Grid>
            </React.Fragment>
        );
    };

    /**
     * Check so see if extras list includes productId
     * @param productId
     * @returns
     */
    const hasExtra = (productId: string): boolean => {
        return extras.map((e) => e.productId).includes(productId);
    };

    /**
    * Check so see if extaItems list includes productId
    * @param productId
    * @returns
    */
    const hasItem = (productId: string): boolean => {
        return extraItems.map((e) => e.productId).includes(productId);
    };

    if (product.proofOfAgeRequired && proofOfAgeAccepted === false) {
        return <WarningDialog open={verification} onClose={handleVerificationClose} >
            <DialogTitle>
                Age Verification Required
            </DialogTitle>

            <DialogContent sx={{ mt: 2, mb: 2 }}>
                This product is not for sale to people under the age of 18. To confirm the recipient is over 18 years, valid photographic ID with a date of birth may be required upon collection or delivery.
            </DialogContent>

            <Stack direction={'row'} spacing={1} padding={1}>
                <Button color={'error'} fullWidth variant='outlined' onClick={() => rejectVerification()}>
                    Cancel
                </Button>
                <Button fullWidth onClick={() => confirmVerification()} variant='contained'>
                    Ok
                </Button>
            </Stack>

        </WarningDialog>;
    }

    return (
        <BlurredDialog open={open} onClose={handleClose} maxWidth={'lg'} PaperProps={{
            sx: {
                height: '100%',
                maxHeight: '65vh',
                width: '75vw',
                [theme.breakpoints.down('lg')]: { width: '60vw', minWidth: '60vw', maxHeight: '80vh' },
                [theme.breakpoints.down('md')]: { minWidth: '75vw' },
                [theme.breakpoints.down('sm')]: { minWidth: '90vw' }
            }
        }}>
            <Grid container direction={'column'} margin={theme.spacing(2)} height={'100%'}>

                <Stack direction='row' justifyContent='space-between'>
                    <ButtonBase onClick={() => handleClose()} sx={{
                        display: 'inline-block', position: 'absolute', top: 0, right: 4,
                        [theme.breakpoints.down('lg')]: { position: 'static' }
                    }}>
                        <Close fontSize="large" />
                    </ButtonBase>
                    {isPortrait && renderAllergens()}
                </Stack>

                <Grid item container direction='row'>

                    {/* IMAGE */}
                    {hideProductImage === false && (!viewExtras || isPortrait) && <Grid item component={'img'} src={product.imageUrl} width='50%' height='auto' maxHeight={'100%'}
                        sx={{
                            borderRadius: theme.spacing(1),
                            objectFit: 'cover', objectPosition: '50% 50%',
                            [theme.breakpoints.down('lg')]: { width: '100%' }
                        }} />}

                    {(product.extraOptions?.length ?? 0) > 0 && viewExtras && !isPortrait &&
                        renderExtras()
                    }

                    {/* Title / Price */}
                    <Grid container direction='row' justifyContent='center' alignContent='space-between'
                        height={'100%'} width='50%'
                        sx={{
                            overflowY: 'auto',
                            [theme.breakpoints.down('lg')]: { overflowY: 'visible', width: '100%' }
                        }}>

                        <Grid item direction='row' columnGap={2} width='100%' mt={1} flexWrap='wrap'>

                            <Typography gutterBottom={true} variant='h4' textTransform={'uppercase'} textAlign='center'
                                sx={{ [theme.breakpoints.down('lg')]: { pb: 1 } }}>
                                {product?.name}
                            </Typography>

                            <Typography gutterBottom={true} variant="h4" textTransform={'uppercase'} textAlign='center'
                                sx={{
                                    [theme.breakpoints.down('lg')]:
                                        { pb: 1 }
                                }}>
                                £{product?.salePrice?.toFixed(2)}
                            </Typography>

                        </Grid>

                        {/* Description */}
                        {product.description &&
                            <MonoTypography variant='h5' textAlign={'center'} m={theme.spacing(1)} mb={0}
                                sx={{
                                    [theme.breakpoints.down('lg')]: { typography: 'h6', mt: 0, mb: 2 }, [theme.breakpoints.down('md')]: { mb: 4 }
                                }}>
                                {product?.description}
                            </MonoTypography>
                        }

                        {/* Display Allergens */}
                        {!isPortrait && renderAllergens()}

                        {/* Qty / Add to Basket */}
                        <Grid item container direction="row" justifyContent="space-evenly" alignContent="center"
                            alignItems="center"
                            sx={{
                                [theme.breakpoints.down('lg')]: { mb: 2 },
                                [theme.breakpoints.down('md')]: { mb: 4 }
                            }} >

                            <Grid item >
                                <Fab color="secondary" onClick={() => setQuantity(quantity - 1)} disabled={quantity === 1 || busy} >
                                    <Remove sx={{ fontSize: theme.spacing(4) }} />
                                </Fab>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="h2" align="center" mb={theme.spacing(2)}>
                                    {quantity}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Fab color="secondary" onClick={() => setQuantity(quantity + 1)} disabled={busy}>
                                    <Add sx={{ fontSize: theme.spacing(4) }} />
                                </Fab>
                            </Grid>
                        </Grid>

                        {/* Display Modifiers */}
                        {product.modifiers?.map((modifier, index) => (
                            <ModifierComponent handleValidationChange={handleValidationChange}
                                key={modifier.label} modifier={modifier} modifierIndex={index}
                                toggleExtra={toggleExtra} toggleItem={toggleItem} hasExtra={hasExtra} hasItem={hasItem} />
                        ))
                        }

                        {(product.extraOptions?.length ?? 0) > 0 && viewExtras && isPortrait &&
                            <DialogContent sx={{ width: '100%', mb: 2, ml: 0, mr: 0, p: 0 }}>
                                {renderExtras()}
                            </DialogContent>
                        }

                        <Stack direction='row' columnGap={2} alignSelf='space-between' width={'100%'}
                            sx={{
                                [theme.breakpoints.down('lg')]: { pb: theme.spacing(2) }
                            }}  >
                            {/* Display Extra Options */}
                            {(product.extraOptions?.length ?? 0) > 0 && !viewExtras &&

                                <Button disabled={busy}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        setViewExtras(!viewExtras);
                                    }}
                                    variant='contained'
                                    sx={{
                                        backgroundColor: theme.palette.grey[900], height: theme.spacing(6), width: '50%'
                                    }}>
                                    <MonoTypography fontSize={'1rem'} mt={theme.spacing(2)} mb={theme.spacing(2)}>
                                        Customise
                                    </MonoTypography>
                                </Button>
                            }
                            <Button disabled={disableAddToBasket || busy} variant="contained" onClick={() => addToBasket(product, menuId)} sx={{
                                height: theme.spacing(6), width: '50%', flexGrow: 1
                            }} >
                                <MonoTypography fontSize={'1rem'} mt={theme.spacing(2)} mb={theme.spacing(2)}>
                                    Add To Basket
                                </MonoTypography>
                            </Button>
                        </Stack>


                    </Grid>

                </Grid>

                <LinearProgress variant={busy ? 'indeterminate' : 'determinate'} value={0} />

            </Grid>


        </BlurredDialog>
    );
};

export const ModifierComponent: React.FC<TModifierComponent> = (
    { handleValidationChange, modifier, modifierIndex, toggleExtra, toggleItem, hasExtra, hasItem }) => {

    // Selected options defaults to 0
    const [selectedOptions, setSelectedOptions] = useState<number>(0);

    // Modifier defaults to an invalid state if options are required
    const [validSelection, setValidSelection] = useState(modifier.maxAllowed >= selectedOptions && modifier.minRequired <= selectedOptions);

    // Every time the validation state of the modifier changes we want to determine whether 'add to basket' should be disabled or not
    useEffect(() => {

        handleValidationChange(validSelection, modifierIndex);

    }, [validSelection]);

    // Every time an option is selected or deselected we want to check if the modifier is valid
    useEffect(() => {
        modifier.maxAllowed >= selectedOptions && modifier.minRequired <= selectedOptions ? setValidSelection(true) : setValidSelection(false);
    }, [selectedOptions]);

    const theme = useTheme();

    /** handle validation state for checkbox, radio, list modifiers  */
    const handleValidation = (listItemCount?: number) => {

        // If the modifier is a checkbox and was previously unselected then we want to set selected options to be 1, otherwise we want to set it to 0
        if (modifier.options.length === 1) {
            selectedOptions === 0 ? setSelectedOptions(1) : setSelectedOptions(0);
        }
        // If the modifier is a radio button then set selected options to be 1
        if (modifier.options.length > 1 && modifier.maxAllowed === 1) {
            setSelectedOptions(1);
        }
        // If the modifier is a list then set Selected options to be the number of list items currently selected
        if (modifier.options.length > 1 && modifier.maxAllowed > 1) {
            assertNotNull(listItemCount);
            setSelectedOptions(listItemCount);
        }
    };

    const displaySelectionText = () => {

        if (modifier.maxAllowed > 1 && modifier.minRequired >= 1) return ` Select between ${modifier.minRequired} and ${modifier.maxAllowed}`;

        if (modifier.maxAllowed > 1 && modifier.maxAllowed < modifier.options.length) {
            return ` Select up to ${modifier.maxAllowed}`;
        }

    };

    return (
        <React.Fragment>

            <Grid key={modifier.label} container direction='column' padding={theme.spacing(2)}>

                <MonoTypography variant='h6' fontWeight={'bold'}>{modifier.label}</MonoTypography>
                <Grid container item direction='column' marginBottom={theme.spacing(2)}>

                    {modifier.minRequired > 0 && !validSelection && <Chip color='error'
                        label={'Required'} sx={{ width: 'max-content', marginTop: theme.spacing(1) }}
                    />}

                    <MonoTypography color={validSelection ? 'inherit' : 'error'}
                        marginTop={theme.spacing(1)}>
                        {displaySelectionText()}
                    </MonoTypography>

                </Grid>

                {modifier.options.length === 1 && (
                    <ModifierCheckBoxOptions modifier={modifier} handleValidation={handleValidation} options={modifier.options[0]} toggleExtra={toggleExtra} toggleItem={toggleItem} />
                )}

                {modifier.options.length > 1 && modifier.maxAllowed === 1 && (
                    <ModifierRadioGroupOptions handleValidation={handleValidation} modifier={modifier} toggleExtra={toggleExtra} toggleItem={toggleItem} />
                )}

                {modifier.options.length > 1 && modifier.maxAllowed > 1 && (
                    <Grid container item justifyContent='center'>
                        <ModifierListOptions modifier={modifier} handleValidation={handleValidation} options={modifier.options} maxAllowed={modifier.maxAllowed}
                            toggleExtra={toggleExtra} toggleItem={toggleItem} hasExtra={hasExtra} hasItem={hasItem} />
                    </Grid>
                )}

                <Divider variant="middle" sx={{ marginTop: theme.spacing(2) }} />

            </Grid>

        </React.Fragment>
    );
};

export const ModifierCheckBoxOptions: React.FC<TModiferCheckBoxOptions> = (
    { modifier, handleValidation, options, toggleExtra, toggleItem }) => {

    /** When the checkbox is selected/deselected we want to toggle the option on the extras list
     * and then validate the modifier
    */
    const handleChange = (options: TExtraOption) => {
        modifier.addAs === 'extra' ? toggleExtra(options) : toggleItem(options);
        handleValidation();
    };

    return (
        <Grid item container alignItems='center' justifyContent='space-between'>

            <FormControlLabel control={<Checkbox onChange={() => handleChange(options)} />}
                label={<MonoTypography variant='h6' >{options.name}</MonoTypography>} />

            <MonoTypography minWidth='max-content' variant='h6'>
                +£{options.salePrice.toFixed(2)}
            </MonoTypography>

        </Grid>
    );

};

export const ModifierRadioGroupOptions: React.FC<TModifierRadioGroupOptions> = (
    { handleValidation, modifier, toggleExtra, toggleItem }) => {

    const [selectedOption, setSelectedOption] = useState<TExtraOption>();

    useEffect(() => {
        if (selectedOption) toggleItemOrExtra(selectedOption);
    }, [selectedOption]);

    const toggleItemOrExtra = (option: TExtraOption) => {
        modifier.addAs === 'extra' ? toggleExtra(option) : toggleItem(option);
    };

    /** When a radio button option is selected it is only necessary to check validation when a radio
     * button is first selected. In all further calls to this method we want to unset the currently
     * selected option from the item/extras array, then set the newly selected option in the item/extras
     * array in the useEffect above
     */
    const handleChange = (option: TExtraOption) => {
        selectedOption ? toggleItemOrExtra(selectedOption) : handleValidation();
        setSelectedOption(option);

    };

    return (

        <FormControl component="fieldset" variant="outlined">
            <RadioGroup>
                {modifier.options.map(option => (

                    <Stack direction='row' alignItems='center' key={option.productId} justifyContent={'space-between'}>

                        <FormControlLabel key={option.productId} onChange={() => handleChange(option)}
                            value={option.name} control={<Radio />}
                            label={<MonoTypography variant='h6'>{option.name}</MonoTypography>} />

                        <MonoTypography minWidth='max-content' variant='h6'>
                            +£{option.salePrice.toFixed(2)}
                        </MonoTypography>

                    </Stack>
                ))}
            </RadioGroup>
        </FormControl>

    );
};


export const ModifierListOptions: React.FC<TModifierListOptions> = (
    { modifier, handleValidation, options, maxAllowed, toggleExtra, toggleItem, hasExtra, hasItem }) => {

    const theme = useTheme();
    const [selectedCount, setSelectedCount] = useState(0);
    // Used to prevent handleValidation being called on first render
    const first = useRef(true);

    useEffect(() => {
        if (first.current) {
            first.current = false;
            return;
        }
        handleValidation(selectedCount);
    }, [selectedCount]);

    /**
     * Increment or decrement count depending on whether an option is selected or deselected
     * @param option
     * @param index
     */
    const toggleSelectedCount = (option: TExtraOption): void => {
        if (modifier.addAs === 'item' ? hasItem(option.productId) : hasExtra(option.productId)) {
            setSelectedCount(selectedCount - 1);

        } else {
            setSelectedCount(selectedCount + 1);

        }
        modifier.addAs === 'item' ? toggleItem(option) : toggleExtra(option);
    };

    const handleListButtonDisabled = (option: TExtraOption) => {
        return (selectedCount >= maxAllowed) &&
            (modifier.addAs === 'item' ? !hasItem(option.productId) : !hasExtra(option.productId)
            );
    };

    const checkIfItemOrExtraHasOption = (option: TExtraOption) => {
        return modifier.addAs === 'item' ? hasItem(option.productId) : hasExtra(option.productId);
    };

    return (
        <List sx={{ width: '90%' }}>
            {options.map(option => (

                <FuocosListItemButton
                    key={option.productId}
                    onClick={() => toggleSelectedCount(option)}
                    selected={checkIfItemOrExtraHasOption(option)}
                    disabled={handleListButtonDisabled(option)}>

                    <ListItemText>
                        <Stack direction='row' alignItems='center'>

                            <MonoTypography variant='h6' align='left' maxWidth={'50%'}>
                                {option.name}
                            </MonoTypography>

                            <Box sx={{ flex: 1 }} />

                            <MonoTypography variant='h6' align='right' marginRight={theme.spacing(2)}>
                                +£{option.salePrice.toFixed(2)}
                            </MonoTypography>

                            {checkIfItemOrExtraHasOption(option) ? <RemoveCircle /> : <AddCircle />}

                        </Stack>

                    </ListItemText>

                </FuocosListItemButton>

            ))}
        </List>
    );
};

export default AddToBasketDialog;
