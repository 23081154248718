import React, { useState, useEffect, useContext } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, query, orderBy, where, CollectionReference, } from 'firebase/firestore';
import { TMenuCategory, RecWithId, TMenuSubCategory, TMenuProduct } from '@hcstechnologies/fuocos-interfaces/src/types';
import { COLLECTION_NAMES } from '@hcstechnologies/fuocos-interfaces/lib/types';
import Loading from '../components/loading';
import { MenuCategories, TMenuCategories } from '../components/menu-categories';
import { MenuCategory } from '../components/menu-category';
import { MenuProduct } from './menu-product';
import { Typography, Grid } from '@mui/material';
import AddToBasketDialog from './add-to-basket-dialog';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { LocationContext } from '../contexts/LocationContext';
import { NoSelectedRestaurant } from '../routes/app';

type TMenuArgs = {
    navBarHeight: number
};

const Menu: React.FC<TMenuArgs> = ({ navBarHeight }) => {

    const analytics = getAnalytics();
    const firestore = useFirestore();
    const { setNoSelectedRestaurant } = useContext(NoSelectedRestaurant);

    const menuCollection = collection(
        firestore,
        COLLECTION_NAMES.MENU
    ) as CollectionReference<RecWithId<TMenuCategory>>;

    const menuQuery = query(
        menuCollection,
        where('active', '==', true),
        where('anonymousAllowed', '==', true),
        orderBy('sortOrder')
    );
    const menuData = useFirestoreCollectionData(menuQuery, { idField: 'id' });

    // Disable deals on comsumer pwa for now

    // const dealCollection = collection(
    //     firestore,
    //     COLLECTION_NAMES.MENU
    // ) as CollectionReference<RecWithId<TDealCategory>>;

    // const dealQuery = query(
    //     dealCollection,
    //     where('active', '==', true),
    //     orderBy('sortOrder')
    // );
    // const dealData = useFirestoreCollectionData(dealQuery, { idField: 'id' });

    const [currentCategory, setCurrentCategory] = useState<string>();
    const [currentProduct, setCurrentProduct] = useState<TMenuProduct>();
    const { location } = useContext(LocationContext);

    // Once data has loaded, set initial category to the first record
    useEffect(() => {
        if (menuData.status === 'success') setCurrentCategory(menuData.data[0].id);
    }, [menuData.status === 'success']);

    /** Handle menu category change */
    const handleChange = (event: React.SyntheticEvent, value: string) => {

        setCurrentCategory(value);
        console.debug(currentCategory);

        /** Iterate over menu categories and log event if category that is clicked on is part of a deal */
        // const deal = dealData.data.find(data => data.id === value && data.dealType);

        // if (deal !== undefined) {
        // logEvent(analytics, 'view_Promotion', {
        //     location_id: location?.location.path,
        //     promotion_id: deal.id,
        //     promotion_name: deal.dealType,
        //     items: [
        //         {
        //             item_name: deal.name,
        //             currency: 'GBP',
        //             index: 0,

        //         }
        //     ]
        // });
        // }

    };

    /** Close dialog */
    const handleClose = (): void => setCurrentProduct(undefined);

    /** Create categories */
    const categories = (): TMenuCategories['categories'] => {

        return menuData.data.map((m) => {
            const exports: TMenuCategories['categories'][0] = {
                id: m.id,
                name: m.name,
            };

            return exports;
        });

    };

    /** Display flattened items for a category */
    const category = (categoryId: string | undefined): RecWithId<TMenuCategory> | TMenuSubCategory | null => {

        if (categoryId === undefined) return null;

        const category = menuData.data.find((m) => m.id === categoryId);
        if (category === undefined) return null;

        return category;

    };

    const selectItem = (product: TMenuProduct) => {

        // If there's no selected location, we want to tell select-restaurant to show dialog via useContext
        if (location === null) {
            setNoSelectedRestaurant(true);
            return;
        }

        setCurrentProduct(product);

        logEvent(analytics, 'select_Item', {
            item_list_id: product.category?.id,
            item_list_name: product.category?.name,
            items: [
                {
                    item_id: product.productId,
                    item_name: product.name,
                    currency: 'GBP',
                    index: 0,
                    item_category: product.category?.name,
                    price: product.salePrice,
                    quantity: product.saleQty
                }
            ]
        });

    };

    // Display loading
    if ([menuData.status].includes('loading')) return <Loading fillViewport={true} message={'Loading Menu'} />;

    // Display error
    if ([menuData.status].includes('error')) return <Typography variant="h1">{menuData.error?.message}</Typography>;

    /**
     * Attempt at component composition to prevent prop drilling
     * Not sure if this is the correct pattern, investigate
     */
    const productComponent = (product: TMenuProduct) => {
        return (
            <MenuProduct
                product={product}
                onAddToBasket={() => selectItem(product)} />
        );
    };

    return (
        <React.Fragment>

            {currentCategory && (
                <MenuCategories
                    categories={categories()}
                    currentValue={currentCategory}
                    onSelectCategory={handleChange}
                    navBarHeight={navBarHeight}>

                    <Grid container direction={'row'} overflow={'hidden'} flexGrow={1}>
                        <MenuCategory
                            displayHeader={false}
                            menuCategory={category(currentCategory)}
                            productComponent={productComponent} />
                    </Grid>

                </MenuCategories>
            )}

            {/* Only construct AddToBasketDialog if location has been set, otherwise on addtobasket display the select location component */}
            {location && (
                <AddToBasketDialog
                    product={currentProduct}
                    locationPath={location?.location.path}
                    open={currentProduct !== undefined}
                    handleClose={handleClose}
                    menuId={currentCategory}
                />
            )}
        </React.Fragment>
    );
};

export default Menu;
