"use strict";
/**
 * NOTES:
 *
 * 1. Naming conventions:
 *    *Id: is the ID for a record, used typically to reference an Id to an external system
 *    *Ref: is a DocumentReference to a specific document in firestore, cannot be used in API calls
 *    *Path: is the full path to a Document in Firestore. Can be used to construct a DocumentReference
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityStatus = exports.OrderStatus = exports.UserRoles = exports.EMAIL_REGEX = exports.TOPIC_NAMES = exports.COLLECTION_NAMES = exports.GOOGLE_MAPS_API_KEY = exports.MINIMUM_VERSION = void 0;
/**
 * Used by some api calls to ensure that the api
 * compatibility  remains constant across versions
 */
exports.MINIMUM_VERSION = '2.0.0';
/**
 * Key to allow access to google maps API
 * TODO: Move to remote config / firebase config
 * */
exports.GOOGLE_MAPS_API_KEY = 'AIzaSyDeW_eS2wbz2TyPrBF00uMEvZ4AgrYutw0';
/**
 * Root collection names for firestore
 */
var COLLECTION_NAMES;
(function (COLLECTION_NAMES) {
    COLLECTION_NAMES["ACCOUNTS"] = "accounts";
    COLLECTION_NAMES["BASKETS"] = "baskets";
    COLLECTION_NAMES["CLEARED_BASKETS"] = "clearedBaskets";
    COLLECTION_NAMES["CONTACTS"] = "contacts";
    COLLECTION_NAMES["DEVICES"] = "devices";
    COLLECTION_NAMES["DISCOUNT_RATES"] = "discountRates";
    COLLECTION_NAMES["INGREDIENTS"] = "ingredients";
    COLLECTION_NAMES["KIOSKS"] = "kiosks";
    COLLECTION_NAMES["LOCATION_TRACKING"] = "locationTracking";
    COLLECTION_NAMES["LOCATIONS"] = "locations";
    COLLECTION_NAMES["MENU"] = "menu";
    COLLECTION_NAMES["ORDER_REF"] = "orderRef";
    COLLECTION_NAMES["ORDERS"] = "orders";
    COLLECTION_NAMES["OUT_OF_STOCK"] = "outOfStock";
    COLLECTION_NAMES["PHOENIX_MENU"] = "phoenixMenu";
    COLLECTION_NAMES["PHOENIX_MENU_SCHEDULE"] = "phoenixMenuSchedule";
    COLLECTION_NAMES["PRIVATE_PRODUCT_DETAILS"] = "privateProductDetails";
    COLLECTION_NAMES["PRIVATE_ORDER_DETAILS"] = "privateOrderDetails";
    COLLECTION_NAMES["PRODUCT_TAGS"] = "productTags";
    COLLECTION_NAMES["PRODUCTS"] = "products";
    COLLECTION_NAMES["SCHEDULER_TIMING_STATS"] = "schedulerTimingStats";
    COLLECTION_NAMES["SETTINGS"] = "settings";
    COLLECTION_NAMES["STAFF_ROTAS"] = "staffRotas";
    COLLECTION_NAMES["STOCK_LEVELS"] = "stockLevels";
    COLLECTION_NAMES["STOCK_LOCATIONS"] = "stockLocations";
    COLLECTION_NAMES["STOCK_MOVEMENTS"] = "stockMovements";
    COLLECTION_NAMES["STOCK_TAKE"] = "stockTake";
    COLLECTION_NAMES["STOCK_TAKE_LEVELS"] = "stockTakeLevels";
    COLLECTION_NAMES["STRIPE_EVENTS"] = "stripeEvents";
    COLLECTION_NAMES["TAX_RATES"] = "taxRates";
    COLLECTION_NAMES["TIMESHEETS"] = "timesheets";
    COLLECTION_NAMES["USERS"] = "users";
    COLLECTION_NAMES["XERO_INVOICE"] = "xeroInvoice";
    COLLECTION_NAMES["PURCHASE_ORDERS"] = "purchaseOrders";
    COLLECTION_NAMES["PURCHASE_ORDER_PRODUCTS"] = "purchaseOrderProducts";
    COLLECTION_NAMES["PURCHASE_ORDER_RECEIPTS"] = "purchaseOrderReceipts";
    COLLECTION_NAMES["PRODUCT_CATEGORIES"] = "productCategories";
    COLLECTION_NAMES["GET_IN_RANGE_LOCATIONS"] = "getInRangeLocations";
    COLLECTION_NAMES["ABANDONED_BASKETS"] = "abandonedBaskets";
    COLLECTION_NAMES["CHECKLIST_TASK_TEMPLATE"] = "checklistTaskTemplate";
    COLLECTION_NAMES["CHECKLIST_TASKS"] = "checklistTasks";
    COLLECTION_NAMES["CHECKLIST_SUMMARY"] = "checklistSummary";
    COLLECTION_NAMES["PRODUCT_MAKE_INSTRUCTIONS"] = "productMakeInstructions";
    COLLECTION_NAMES["STRIPE_WEBHOOKS"] = "stripeWebhooks";
})(COLLECTION_NAMES = exports.COLLECTION_NAMES || (exports.COLLECTION_NAMES = {}));
/** Topic names that can be subscribed to */
exports.TOPIC_NAMES = {
    KIOSK_ALERTS: 'storageUpdate'
};
/** Simple RegEx to validatr email address */
exports.EMAIL_REGEX = /[^@]+@[^\.]+\..+/g;
/** Description of User Roles */
exports.UserRoles = {
    restaurantAdmin: {
        name: 'Restaurant Admin',
    },
    payments: {
        name: 'Payments Webhooks',
    },
    driver: {
        name: 'Delivery Driver',
    },
    kiosk: {
        name: 'Kiosk App',
    },
    franchisorAdmin: {
        name: 'Franchisor Admin',
    },
    api: {
        name: 'API User',
    },
};
exports.OrderStatus = {
    NEW: { code: 0, name: 'New' },
    AWAITING_PAYMENT: { code: 10, name: 'Awaiting Payment' },
    PENDING: { code: 20, name: 'Pending' },
    PREP: { code: 30, name: 'Prep' },
    BAKE: { code: 40, name: 'Bake' },
    BOX: { code: 45, name: 'Boxing Up' },
    READY_FOR_COLLECTION: { code: 60, name: 'Ready For Collection' },
    READY_FOR_DELIVERY: { code: 65, name: 'Ready for Delivery' },
    OUT_FOR_DELIVERY: { code: 67, name: 'Out For Delivery' },
    // Completion phases, anything >= 70
    DELIVERED: { code: 70, name: 'Delivered' },
    COLLECTED: { code: 80, name: 'Collected' },
    // Complete
    COMPLETE: { code: 100, name: 'Complete' },
    // Temporary Failures
    DRIVER_UNAVAILABLE: { code: 25, name: 'Driver Unavailable' },
    INVALID_ADDRESS: { code: 19, name: 'Invalid Address' },
    // Permanent failures
    OUT_OF_HOURS: { code: -20, name: 'Out Of Hours' },
    OUT_OF_RANGE: { code: -30, name: 'Out Of Range' },
    FAILED_PAYMENT: { code: -50, name: 'Failed Payment' },
    /** @deprecated */
    REFUNDED: { code: -70, name: 'Refunded' },
    CANCELLED: { code: -100, name: 'Cancelled' },
};
/** Status of availability slot, used to provide information to consumer on considerations during slot selection, multiple status can be displayed */
var AvailabilityStatus;
(function (AvailabilityStatus) {
    /** Standard delivery, nothing to show consumer */
    AvailabilityStatus["STANDARD"] = "S";
    /** Not many slots available, book fast */
    AvailabilityStatus["LOW_AVAILABILITY"] = "L";
    /** There are other deliveries in your area so your delivery would have lower carbon emmissions */
    AvailabilityStatus["GREEN"] = "G";
})(AvailabilityStatus = exports.AvailabilityStatus || (exports.AvailabilityStatus = {}));
