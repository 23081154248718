import { useFirestore, useFunctions, useFirestoreDocData, useAuth, } from 'reactfire';

import {
    TMenuProduct, TValidateBasketRequestItem, TValidateBasketRequest,
    TBasket, TCheckoutResponse, TPayOptions, TPayResponse,
} from '@hcstechnologies/fuocos-interfaces/src/types';
import {
    MINIMUM_VERSION
} from '@hcstechnologies/fuocos-interfaces/lib/types';
import { httpsCallable } from 'firebase/functions';
import { COLLECTION_NAMES } from '@hcstechnologies/fuocos-interfaces/lib/types';
import { DocumentReference, doc } from 'firebase/firestore';
import { noop } from 'lodash';
import { getAnalytics, logEvent } from 'firebase/analytics';

/**
 * Features to manage the basket
 * @returns
 */
export const useBasket = () => {

    const analytics = getAnalytics();
    const functions = useFunctions();
    const auth = useAuth();
    const firestore = useFirestore();

    if (auth.currentUser === null) throw new Error('No currentUser');

    // TODO: Make this typesafe
    console.debug(`get:${COLLECTION_NAMES.BASKETS}/${auth.currentUser.uid}`);
    const docRef = doc(firestore, COLLECTION_NAMES.BASKETS, auth.currentUser.uid) as DocumentReference<TBasket>;

    /** Basket for logged in user */
    const basketDoc = useFirestoreDocData(docRef);

    /** Add product to basket and revalidate */
    const addProductToBasket = async (
        product: TMenuProduct,
        quantity: number,
        extras: TValidateBasketRequestItem['extras'],
        extraItems: TValidateBasketRequest['items'],
        remove: TValidateBasketRequestItem['remove'],
        locationPath: string,
        userLocation: string,
        menuId?: string
    ): Promise<TBasket> => {

        const validateBasket = httpsCallable<TValidateBasketRequest, TBasket>(functions, 'validateBasket');

        if (basketDoc.status !== 'success') throw new Error('Basket is not Loaded');

        const items: TValidateBasketRequestItem[] = (
            basketDoc.data?.items || []
        ).concat({
            productId: product.productId,
            productName: product.name,
            quantity,
            extras,
            remove,
            ...(menuId !== undefined && { menuId })
        }).concat(extraItems.map((item) => (
            {
                productId: item.productId,
                productName: item.productName,
                quantity: item.quantity,
                extras: item.extras,
                remove: item.remove,
                ...(menuId !== undefined && { menuId })
            })
        ));

        const newBasket: TValidateBasketRequest = {
            locationPath,
            userLocation,
            items,
            originV2: 'web',
            appVersion: MINIMUM_VERSION
        };

        logEvent(analytics, 'validate_basket');
        const validatedBasket = await validateBasket(newBasket);

        return validatedBasket.data;
    };

    /** Call checkout endpoint for basket */
    const checkout = async (): Promise<TCheckoutResponse> => {

        const checkout = httpsCallable<null, TCheckoutResponse>(functions, 'checkout');

        logEvent(analytics, 'checkout');
        const exports = await checkout();

        return exports.data;
    };

    /** Send pay request to the server */
    const pay = async (payRequest: TPayOptions): Promise<TPayResponse> => {

        const payFn = httpsCallable<TPayOptions, TPayResponse>(functions, 'pay');

        logEvent(analytics, 'pay');
        const exports = await payFn(payRequest);

        return exports.data;

    };

    const addDealToBasket = noop;

    const clearBasket = async () => {

        const clearBasket = httpsCallable<void, void>(functions, 'clearBasket');

        await clearBasket();

        basketDoc.data.items.forEach(basketItem => {

            logEvent(analytics, 'remove_from_cart', {
                currency: 'GBP',
                items: [
                    {
                        item_id: basketItem.productId,
                        item_name: basketItem.productName,
                        index: 0,
                        quantity: basketItem.quantity,
                    }
                ]
            });

        });

    };

    return {
        addProductToBasket,
        basketDoc,
        addDealToBasket,
        clearBasket,
        checkout,
        pay,
    };
};
