import {
    TCheckoutResponse, TAvailabilitySlot, TUser, TGetAddressIoResponse,
    TPayOptions, TOrderType, TPayResponse
} from '@hcstechnologies/fuocos-interfaces/src/types';
import React, { useEffect, useState, useContext, useRef } from 'react';
import Loading from '../components/loading';
import { useBasket } from '../hooks/use-basket';
import SlotButton from '../components/slot-button';
import {
    FormControl, InputLabel, Select,
    MenuItem, DialogContent, Grid, Typography, TextField,
    Stack, List, ListItem, Button, Box, Divider, useTheme,
    LinearProgress, useMediaQuery
} from '@mui/material';
import { LocalPizza } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { normaliseError } from '../shared/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth, useFirestore, useFirestoreDocData, useRemoteConfig } from 'reactfire';
import { COLLECTION_NAMES } from '@hcstechnologies/fuocos-interfaces/lib/types';
import { doc, DocumentReference } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { compact } from 'lodash';
import StripePay from '../components/stripe-pay';
import { PaymentIntent } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { assert } from '../shared/utils';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { BlurredDialog, MonoTypography } from '@hcstechnologies/fuocos-themes';
import { getString } from 'firebase/remote-config';
import { LocationContext, PostcodeContext } from '../contexts/LocationContext';
import { NoSelectedRestaurant } from './app';


const CheckoutPage = () => {
    const analytics = getAnalytics();
    const { checkout, basketDoc, pay } = useBasket();
    const [checkoutOptions, setCheckoutOptions] = useState<TCheckoutResponse>();
    const [payRequest, setPayRequest] = useState<
        Partial<TPayOptions> | TPayOptions
    >();
    const [payResponse, setPayResponse] = useState<TPayResponse>();
    const [selectedSlot, setSelectedSlot] = useState<{ type: 'DELIVERY' | 'COLLECTION'; slot: string }>();
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState<string>();
    const [collectionVisible, setCollectionVisible] = useState(true);
    const [deliveryVisible, setDeliveryVisible] = useState(true);
    const [addresses, setAddresses] = useState<TGetAddressIoResponse>();
    const auth = useAuth();
    const firestore = useFirestore();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const theme = useTheme();
    const remoteConfig = useRemoteConfig();
    const { location } = useContext(LocationContext);
    const { postcode: userLocation } = useContext(PostcodeContext);
    const { setNoSelectedRestaurant } = useContext(NoSelectedRestaurant);
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));
    const customerDetailsRef = useRef<HTMLElement>(null);

    if (auth.currentUser === null) throw new Error('User is not logged in');

    const userRef = doc(firestore, `${COLLECTION_NAMES.USERS}/${auth.currentUser.uid}`
    ) as DocumentReference<TUser>;
    const userDoc = useFirestoreDocData(userRef);

    const handleError = (error: string) => {
        setError(error);
    };

    useEffect(() => {
        async function fetchAddresses() {

            if (userLocation) {

                const postcode = userLocation;


                const GET_ADDRESS_API_KEY = getString(remoteConfig, 'get_address_api_key');


                const url = `https://api.getAddress.io/find/${postcode}?api-key=${GET_ADDRESS_API_KEY}&expand=true&sort=true`;


                fetch(url).then(response => response.json()).then(json => {
                    console.debug(json);

                    setAddresses(json);

                }).catch((error) => handleError(error.message));

            } else {
                setNoSelectedRestaurant(true);
            }
        }
        fetchAddresses();

        // Check whether there is a local restaurant for user's location
        try {
            if (location === null && !NoSelectedRestaurant) throw Error('No local restaurant');
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        }

    }, []);

    const formik = useFormik({
        initialValues: {
            customerName: '',
            customerEmail: '',
            customerPhone: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
        },
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            customerName: Yup.string().required('Required').min(2).max(20),
            customerEmail: Yup.string().nullable(),
            customerPhone: Yup.string().required(),
            address1: selectedSlot?.type === 'DELIVERY' ? Yup.string().required() : Yup.string().nullable(),
            address2: Yup.string().nullable(),
            city: selectedSlot?.type === 'DELIVERY' ? Yup.string().required() : Yup.string().nullable(),
            postcode: selectedSlot?.type === 'DELIVERY' ? Yup.string().required() : Yup.string().nullable(),
        }),
        onSubmit: async (values) => {

            console.debug('handleSubmitPayment', values);

            try {
                setBusy(true);

                assert(checkoutOptions !== undefined, 'Cannot pay until checkout process has been initiated');

                // Check to make sure the store has an available collection slot
                assert(selectedSlot !== undefined, 'Cannot find an available collection slot');

                const payArgs: TPayOptions = {
                    orderPath: checkoutOptions.orderPath,
                    orderType: selectedSlot.type,
                    requestedTime: DateTime.fromISO(selectedSlot.slot).toJSDate(),
                    customerName: values.customerName,
                    customerEmail: values.customerEmail,
                    customerPhone: values.customerPhone,
                    ...selectedSlot.type === 'DELIVERY' && {
                        address: {
                            address1: values.address1,
                            address2: values.address2,
                            city: values.city,
                            postcode: values.postcode,
                        },
                    },

                };

                console.debug('payArgs', payArgs);

                setPayResponse(await pay(payArgs));


                console.debug('payResponse', payResponse);

            } catch (error) {
                console.error(error);
                const e = normaliseError(error);
                enqueueSnackbar(e.message, { variant: 'error' });
            }

            setBusy(false);

        }
    });

    // Get Checkout Object
    useEffect(() => {

        if (basketDoc.status !== 'success') return;

        checkout().then((response) => {

            setCheckoutOptions(response);

            const basketItems = basketDoc.data.validatedBasket.allProducts.map(
                (product) => {
                    return {
                        item_id: product.productId,
                        item_name: product.productName,
                        price: product.unitPrice,
                        quantity: product.quantity,
                    };
                }
            );

            logEvent(analytics, 'begin_checkout', {
                currency: 'GBP',
                basketItems,
            });

        }).catch((error) => {
            handleError(error.message);
        });

    }, [basketDoc.status === 'success']);

    /** When a customer selects a collection/delivery slot we want to scroll to the customer
     * details section after it has been mounted*/
    useEffect(() => {
        payRequest?.orderType && customerDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [payRequest]);


    if ([basketDoc.status, userDoc.status].includes('loading')) return <Loading message="Loading..." />;

    if ([basketDoc.status, userDoc.status].includes('error')) return <Typography>{basketDoc.error ?? userDoc.error}</Typography>;

    if (checkoutOptions === undefined && !error) return <Loading message="Getting Checkout Options" />;

    const handleSlotSelect = (ev: React.MouseEvent, orderType:
        TOrderType, slot: TAvailabilitySlot) => {
        console.debug('handleSlotSelect', ev, orderType, slot);
        if (orderType === 'COLLECTION') {
            setDeliveryVisible(false);
        }

        if (orderType === 'DELIVERY') {
            setCollectionVisible(false);
        }

        if (
            orderType === payRequest?.orderType &&
            isSameTime(slot, payRequest.requestedTime)
        ) {
            setPayRequest({
                ...payRequest,
                requestedTime: undefined,
                orderType: undefined,
                address: undefined,
            });
            if (orderType === 'COLLECTION') {
                setDeliveryVisible(true);
            }
            if (orderType === 'DELIVERY') {
                setCollectionVisible(true);
            }
        } else {
            setPayRequest({
                ...payRequest,
                requestedTime: new Date(slot.from),
                orderType,
                address: undefined,
            });
        }
    };

    // Compare time from slots to the Date object in the PayRequest object
    const isSameTime = (
        slot: TAvailabilitySlot,
        time: Date | undefined
    ): boolean => {
        return (
            time instanceof Date &&
            DateTime.fromJSDate(time).equals(DateTime.fromISO(slot.from))
        );
    };

    /** Redirect to order tracker on payment */
    const handleOnPayment = (paymentIntent: PaymentIntent) => {
        setBusy(true);

        console.debug('handleOnPayment', paymentIntent);
        if (checkoutOptions?.orderPath === undefined)
            throw new Error('Invalid Order Path');

        const orderId = checkoutOptions.orderPath.match(/([^/]+)\/?$/g)?.[0];
        if (orderId === undefined) throw new Error('Invalid OrderId');

        navigate(`/orders/${orderId}`);
    };

    const handleSelect = (ev: React.MouseEvent, orderType:
        TOrderType, slot: TAvailabilitySlot) => {
        setSelectedSlot({ type: orderType, slot: slot.from });
        handleSlotSelect(ev, orderType, slot);
    };

    return (
        <Grid height={'100%'} container direction={isMedium ? 'column' : 'row'}
            pt={theme.spacing(4)} overflow={'auto'} flexWrap={'nowrap'}>
            {error && (
                <React.Fragment>
                    <BlurredDialog open={error !== ''}>
                        <Box sx={{
                            textAlign: 'center',
                        }}>
                            <DialogContent sx={{ mt: 2, mb: 2 }}>
                                {error}
                            </DialogContent>

                            <Button
                                variant="contained"
                                sx={{ width: '80%', mb: 2 }}
                                onClick={() => navigate('/')}
                            >
                                <MonoTypography
                                    marginTop={theme.spacing(1)}
                                    marginBottom={theme.spacing(2)}
                                >
                                    Back To Menu
                                </MonoTypography>
                            </Button>
                        </Box>
                    </BlurredDialog>;
                </React.Fragment>
            )}
            {/* Checkout Options */}
            <Grid item xs={isMedium ? 4 : true} container={isMedium ? true : false}
                height={'100%'} overflow={'auto'} p={theme.spacing(1)}
                justifyContent={isMedium ? 'center' : 'flex-start'}>

                {collectionVisible && (
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom >
                            Available Collection Slots
                        </Typography>

                        <Grid container direction={'row'}
                            justifyContent={isMedium ? 'center' : 'flex-start'}>

                            {checkoutOptions?.availableSlots.COLLECTION.length === 0 && (
                                <Grid item component={Typography} variant="body2">
                                    {checkoutOptions.collectionSlotsMessage ??
                                    'Sorry, no collection slots available'}
                                </Grid>
                            )}

                            {checkoutOptions?.availableSlots.COLLECTION.map((slot) => {
                                return (
                                    <Grid
                                        item
                                        component={SlotButton}
                                        key={slot.from}
                                        slot={slot}
                                        onSelect={(ev: React.MouseEvent<HTMLButtonElement>) => {
                                            handleSelect(ev, 'COLLECTION', slot);
                                        }
                                        }
                                        selected={
                                            selectedSlot?.type === 'COLLECTION' && isSameTime(slot, payRequest?.requestedTime)
                                        }
                                    />
                                );
                            })}
                        </Grid>
                    </React.Fragment>
                )}

                {deliveryVisible && (
                    <React.Fragment>
                        <Typography variant="h6" gutterBottom>
                            Available Delivery Slots
                        </Typography>

                        <Grid container direction={'row'}
                            justifyContent={isMedium ? 'center' : 'flex-start'}>

                            {checkoutOptions?.availableSlots.DELIVERY.length === 0 && (
                                <Grid item component={Typography} variant="body2">
                                    {checkoutOptions.deliverySlotsMessage ??
                                    'Sorry, no delivery slots available'}
                                </Grid>
                            )}

                            {checkoutOptions?.availableSlots.DELIVERY.map((slot) => {
                                return (
                                    <Grid
                                        component={SlotButton}
                                        key={slot.from}
                                        slot={slot}
                                        onSelect={(ev: React.MouseEvent<HTMLButtonElement>) => {
                                            handleSelect(ev, 'DELIVERY', slot);
                                        }
                                        }
                                        selected={
                                            selectedSlot?.type === 'DELIVERY' && isSameTime(slot, payRequest?.requestedTime)
                                        }
                                    />
                                );
                            })}
                        </Grid>
                    </React.Fragment>
                )}

                {/* Display User Details */}
                {payRequest?.orderType && (
                    <React.Fragment>
                        <Typography ref={customerDetailsRef} variant="h6" gutterBottom mt={isMedium ? 3 : 0}>
                            Customer details
                        </Typography>
                        <Grid item width={isMedium ? '100%' : 'auto'}>
                            <Stack direction={'column'} mb={1}>
                                <TextField
                                    {...formik.getFieldProps('customerName')}
                                    fullWidth
                                    autoComplete="name"
                                    id="customerName"
                                    name="customerName"
                                    label="Customer Name *"
                                    placeholder="Customer Name"
                                    variant={'outlined'}
                                    error={formik.touched.customerName && Boolean(formik.errors.customerName)}
                                    helperText={formik.touched.customerName && formik.errors.customerName}
                                    sx={{ mb: 1 }}
                                />

                                <TextField
                                    {...formik.getFieldProps('customerEmail')}
                                    type={'email'}
                                    sx={{ mb: 1 }}
                                    fullWidth
                                    id="customerEmail"
                                    name="customerEmail"
                                    label="Customer Email (Optional)"
                                    variant={'outlined'}
                                    error={formik.touched.customerEmail && Boolean(formik.errors.customerEmail)}
                                    helperText={formik.touched.customerEmail && formik.errors.customerEmail}
                                    placeholder="Customer Email"
                                    autoComplete="email"
                                />

                                <TextField
                                    {...formik.getFieldProps('customerPhone')}
                                    type={'tel'}
                                    sx={{ mb: 1 }}
                                    fullWidth
                                    id="customerPhone"
                                    name="customerPhone"
                                    label="Customer Phone *"
                                    variant={'outlined'}
                                    error={formik.touched.customerPhone && Boolean(formik.errors.customerPhone)}
                                    helperText={formik.touched.customerPhone && formik.errors.customerPhone}
                                    placeholder="Customer Phone"
                                />
                            </Stack>
                        </Grid>

                    </React.Fragment>
                )}


                {payRequest?.orderType === 'DELIVERY' && (
                    <React.Fragment>
                        <Divider variant={'middle'} sx={{ mb: 1 }} />

                        <Stack rowGap={1}>

                            <Typography variant='h6' gutterBottom>
                                Delivery Address
                            </Typography>

                            <FormControl size={'small'} fullWidth sx={{ mb: 1 }}>
                                <InputLabel id="select-address-label">Select Address</InputLabel>
                                <Select
                                    labelId="select-address-label"
                                    id="select-address"
                                    label="Address"
                                    onChange={(ev) => {
                                        const index = Number(ev.target.value);
                                        const address = addresses?.addresses[index];
                                        formik.setFieldValue('address1', address?.line_1, true);
                                        formik.setFieldValue('address2', address?.line_2, true);
                                        formik.setFieldValue('city', address?.town_or_city, true);
                                        formik.setFieldValue('postcode', addresses?.postcode, true);

                                        setTimeout(() => {
                                            formik.setFieldTouched('address1');
                                            formik.setFieldTouched('address2');
                                            formik.setFieldTouched('city');
                                            formik.setFieldTouched('postcode');
                                        });
                                    }}>

                                    {addresses?.addresses?.map((address, index) => {
                                        return (
                                            <MenuItem key={index} value={index}>
                                                <MonoTypography>
                                                    {compact([
                                                        address.line_1,
                                                        address.line_2,
                                                        address.town_or_city,
                                                        addresses.postcode,
                                                    ]).join(', ')}
                                                </MonoTypography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <TextField
                                {...formik.getFieldProps('address1')}
                                autoComplete='off'
                                sx={{ mb: 1 }}
                                disabled={busy}
                                fullWidth
                                id="address1"
                                name="address1"
                                label="Address 1 *"
                                variant={'outlined'}
                                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                helperText={formik.touched.address1 && formik.errors.address1} />

                            <TextField
                                {...formik.getFieldProps('address2')}
                                autoComplete='off'
                                sx={{ mb: 1 }}
                                disabled={busy}
                                fullWidth
                                id="address2"
                                name="address2"
                                label="Address 2"
                                variant={'outlined'}
                                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                helperText={formik.touched.address2 && formik.errors.address2} />

                            <Stack direction={'row'} columnGap={2}>
                                <TextField
                                    {...formik.getFieldProps('city')}
                                    autoComplete='off'
                                    sx={{ mb: 1 }}
                                    disabled={busy}
                                    fullWidth
                                    id="city"
                                    name="city"
                                    label="City/Town *"
                                    variant={'outlined'}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city} />

                                <TextField
                                    {...formik.getFieldProps('postcode')}
                                    autoComplete='off'
                                    sx={{ mb: 1 }}
                                    disabled={busy}
                                    fullWidth
                                    id="postcode"
                                    name="postcode"
                                    label="Postcode *"
                                    variant={'outlined'}
                                    error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                                    helperText={formik.touched.postcode && formik.errors.postcode} />
                            </Stack>
                        </Stack>
                    </React.Fragment>
                )}

            </Grid>

            <Grid item borderRight={'1px solid lightgrey'} />

            {/* <Grid item>
                <Divider flexItem={true} orientation="vertical" variant={'inset'} />
            </Grid> */}

            {
                isMedium &&
                <Divider
                    sx={{ marginBottom: theme.spacing(3) }}
                />
            }

            {/* View Basket */}
            <Grid item xs={isMedium ? true : 4} container direction={'column'} p={1} flexWrap='nowrap' overflow='auto'>
                <Stack direction={'row'} pt={isMedium ? 1 : 0}>
                    <LocalPizza fontSize="large" sx={{ mr: 2 }} />
                    <Typography variant="h6">Your Basket</Typography>
                </Stack>

                <Grid item xs={payRequest || isMedium ? 'auto' : true} overflow={'auto'} minHeight={'200px'}>
                    <List>
                        {basketDoc.data.validatedBasket.items.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    <Stack direction={'row'} width={'100%'}>
                                        <MonoTypography>
                                            {item.quantity}x {item.productName}
                                        </MonoTypography>

                                        <Box
                                            flexGrow={1}
                                            sx={{
                                                borderBottomWidth: '1px',
                                                borderBottomStyle: 'dashed',
                                                borderBottomColor: theme.palette.grey.A400,
                                                marginRight: theme.spacing(1),
                                                marginLeft: theme.spacing(1),
                                            }}
                                        />

                                        <MonoTypography>£{item.baseAmount.toFixed(2)}</MonoTypography>
                                    </Stack>
                                </ListItem>
                                {item.extras.map((extra, index) => (
                                    <ListItem key={index}>
                                        <Stack direction={'row'} width={'100%'} sx={{ paddingLeft: theme.spacing(2) }}>
                                            <MonoTypography>
                                                + {extra.quantity}x {extra.productName}
                                            </MonoTypography>

                                            <MonoTypography>
                                                &nbsp;£{extra.unitPrice.toFixed(2)}
                                            </MonoTypography>
                                        </Stack>
                                    </ListItem>
                                ))}
                            </React.Fragment>
                        ))}
                    </List>
                </Grid>

                <Divider
                    sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
                />

                <List>
                    <ListItem>
                        <Stack direction={'row'} width={'100%'}>
                            <MonoTypography>
                                Total
                            </MonoTypography>

                            <Box
                                flexGrow={1}
                                sx={{
                                    borderBottomWidth: '1px',
                                    borderBottomStyle: 'dashed',
                                    borderBottomColor: theme.palette.grey.A400,
                                    marginRight: theme.spacing(1),
                                    marginLeft: theme.spacing(1),
                                }}
                            />

                            <MonoTypography>
                                £{basketDoc.data.validatedBasket.totalAmount.toFixed(2)}
                            </MonoTypography>
                        </Stack>
                    </ListItem>

                    <ListItem>
                        <Stack direction={'row'} width={'100%'}>
                            <MonoTypography>
                                Includes VAT
                            </MonoTypography>

                            <Box
                                flexGrow={1}
                                sx={{
                                    borderBottomWidth: '1px',
                                    borderBottomStyle: 'dashed',
                                    borderBottomColor: theme.palette.grey.A400,
                                    marginRight: theme.spacing(1),
                                    marginLeft: theme.spacing(1),
                                }}
                            />

                            <MonoTypography>
                                £{basketDoc.data.validatedBasket.totalTax.toFixed(2)}
                            </MonoTypography>
                        </Stack>
                    </ListItem>
                </List>

                {/* Display Pay Button */}
                {payResponse === undefined && (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            size="large"
                            disabled={formik.dirty === false || formik.isValid === false || busy}
                            type='submit'
                            onClick={() => formik.handleSubmit()}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.neutral.main,
                            }}
                        >
                            Pay
                        </Button>

                        {busy && <LinearProgress variant="indeterminate" />}
                    </React.Fragment>
                )}

                {/* Display Payments Element */}
                {payResponse && (
                    <Grid
                        item
                        component={StripePay}
                        paymentIntent={payResponse.paymentsKey ?? ''}
                        onPayment={handleOnPayment}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default CheckoutPage;
