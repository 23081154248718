import React, { useEffect, useMemo } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import {
    FirestoreProvider, AuthProvider, RemoteConfigProvider,
    useFirebaseApp, useInitRemoteConfig, FunctionsProvider
} from 'reactfire';
import { connectAuthEmulator } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Loading from '../components/loading';
import { deviceDetect, isAndroid, isIOS } from 'react-device-detect';


/**
 * Initialise Firebase components
 */
export const FirebaseComponents: React.FC<React.PropsWithChildren<any>> = ({ children }) => {

    const app = useFirebaseApp();
    const auth = getAuth(app);
    const firestore = getFirestore(app);

    // Log on mount
    useEffect(() => console.debug('FirebaseComponents'), []);

    // Dev/Emulator is in US, Prod is in EU
    const functions = useMemo(() => {
        console.debug(`app.options.projectId: ${app.options.projectId}`);
        return getFunctions(app, app.options.projectId === 'fuocos-pizza-prod' ? 'europe-west2' : 'us-central1');
    }, []);

    // Redirect if user is on mobile device
    useEffect(() => {

        const device = deviceDetect(navigator.userAgent);

        if (isAndroid || isIOS) {
            logEvent(getAnalytics(), 'redirect_to_appstores', device);
            console.debug('redirect_to_appstores', device);
            location.replace('https://get.fuocos.pizza/');
        }

    }, []);

    // Setup emulators
    useEffect(() => {

        if (process.env.NODE_ENV !== 'production') {
            console.debug('Connecting to Emulators...');
            connectFirestoreEmulator(firestore, 'localhost', 8080);
            connectFunctionsEmulator(functions, 'localhost', 5001);
            connectAuthEmulator(auth, 'http://localhost:9099');
        }

    }, []);

    // Fetch & activate remote config
    const { status, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {

        console.debug('useInitRemoteConfig');

        const remoteConfig = getRemoteConfig(firebaseApp);

        remoteConfig.settings = {
            minimumFetchIntervalMillis: process.env.NODE_ENV === 'production' ? 60000 : 10000,
            fetchTimeoutMillis: 10000,
        };

        remoteConfig.defaultConfig = {
            opening_soon: true,
            get_address_api_key: 'ftXopWP8DUW6urBWWzFB-A1096',
            stripe_publishable_key: 'pk_test_3vEK7nH7tvV3zY2p9uaiUkJo00ReFQ2aEC',
        };

        await fetchAndActivate(remoteConfig);

        return remoteConfig;

    });

    // Do not render until remote config has loaded
    if (status === 'loading') return <Loading message='Initialising...' fillViewport={true} />;

    // Render Firebase providers
    return (
        <RemoteConfigProvider sdk={remoteConfigInstance}>
            <AuthProvider sdk={auth}>
                <FirestoreProvider sdk={firestore}>
                    <FunctionsProvider sdk={functions}>
                        {children}
                    </FunctionsProvider>
                </FirestoreProvider>
            </AuthProvider>
        </RemoteConfigProvider>
    );

};

export default FirebaseComponents;
