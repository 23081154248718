import { TAvailabilitySlot } from '@hcstechnologies/fuocos-interfaces/src/types';
import React from 'react';
import { ButtonBase, Typography, Stack, SxProps } from '@mui/material';
import FormatDate from '../components/format-date';

type SlotButtonArgs = {
    slot: TAvailabilitySlot;
    onSelect: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    selected: boolean;
};

const SlotButton: React.FC<SlotButtonArgs> = ({ slot, onSelect, selected }) => {

    const standardStyle: SxProps = {
        width: '75px',
        height: '30px',
        borderRadius: '10px',
        border: '1px solid gray',
        margin: 1,
    };

    const selectedStyle: SxProps = {
        ...standardStyle,
        variant: 'contained',
        backgroundColor: 'secondary.light',
    };


    return (
        <ButtonBase onClick={onSelect} sx={selected ? selectedStyle : standardStyle}>

            <Stack>
                <Typography variant='body2'>
                    <FormatDate date={slot.from} />
                </Typography>

                {/*{slot.status.map(val => <Typography variant='caption' key={val}>{val}</Typography>)}*/}

            </Stack>
        </ButtonBase >
    );
};

export default SlotButton;
