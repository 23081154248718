import React, { useEffect, useState } from 'react';
import { List, Typography, Box } from '@mui/material';
import { useAuth, useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, CollectionReference, doc, orderBy, query, where } from 'firebase/firestore';
import { COLLECTION_NAMES } from '@hcstechnologies/fuocos-interfaces/lib/types';
import { TOrder, RecWithId } from '@hcstechnologies/fuocos-interfaces/src/types';
import Loading from '../components/loading';
import { MonoTypography, FuocosListItemButton } from '@hcstechnologies/fuocos-themes';
import OrderHistoryDialog from './order-history-dialog';
import { formatTimestamp } from '../shared/utils';
import { useParams } from 'react-router-dom';
const OrderHistoryPage: React.FC = () => {
    const [dialogOpen, setDialogOpen] = useState<boolean>();
    const [orderId, setOrderId] = useState<string>();
    const firestore = useFirestore();
    const params = useParams();

    const auth = useAuth();
    if (auth.currentUser === null) throw new Error('Not Authenticated');

    const userRef = doc(firestore, COLLECTION_NAMES.USERS, auth.currentUser.uid);
    console.debug(userRef.path);

    const orderHistoryCollection = collection(firestore, COLLECTION_NAMES.ORDERS) as CollectionReference<RecWithId<TOrder>>;
    const orderHistoryQuery = query(orderHistoryCollection, where('userRef', '==', userRef), orderBy('requestedTime', 'desc'));
    const orderHistoryData = useFirestoreCollectionData(orderHistoryQuery, { idField: 'id' });

    const handleDialog = (orderId: string | undefined) => {
        setOrderId(orderId);
        setDialogOpen(true);
    };

    useEffect(() => {

        if (params.orderId && orderHistoryData.data?.find(data => data.id === params.orderId)) {
            handleDialog(params.orderId);
        }

    }, [params.orderId, orderHistoryData.data]);


    if (orderHistoryData.status === 'loading') return <Loading message='Loading Order History...' />;
    if (orderHistoryData.status === 'error') return <Typography>{orderHistoryData.error}</Typography>;


    const handleClose = (): void => setDialogOpen(undefined);

    return (
        <Box>
            <Typography variant="h4" sx={{ textAlign: 'center', pb: 2 }}>Orders</Typography>
            <List sx={{
                display: 'flex', flexDirection: 'column'
            }}>
                {
                    orderHistoryData.data.map(order =>
                        <FuocosListItemButton key={order.id} onClick={() => handleDialog(order.id)} sx={{ height: '10vh', width: '50vw', alignSelf: 'center', borderRadius: 1 }}>

                            <MonoTypography fontWeight={'700'} fontSize='1.2rem'>
                                Order #{order.orderNumber} {order.status.name} £{order.validatedBasket.totalAmount.toFixed(2)}
                            </MonoTypography>

                            <MonoTypography fontWeight={'700'} fontSize='1.2rem' sx={{ ml: 'auto', mr: 2 }}>
                                {formatTimestamp(order.createdTime)}
                            </MonoTypography>

                            <MonoTypography fontWeight={700} fontSize='1.2rem'>
                                {order.locationName}
                            </MonoTypography>


                        </FuocosListItemButton>
                    )
                }
            </List>

            {dialogOpen !== undefined && (
                <OrderHistoryDialog open={orderId !== undefined} handleClose={handleClose} orderId={orderId} />
            )}
        </Box>
    );

};

export default OrderHistoryPage;
